import showHelp from "../Help";
import store from "@/store";

export default class UI {
    constructor(scene) {
        this.scene = scene;
        this.camera;
        this.topOutline = 135;
        this.sounds = {};
    }

    addAllUI() {
        this.addScreeenMode();
        this.addQuestion();
        this.addWorkplace();
    }

    playSound(sound, config = {volume: 0.5}) {
        if (this.sounds[sound]) {
            this.sounds[sound].play(config)
        } else {
            this.sounds[sound] = this.scene.sound.add(sound);
            this.sounds[sound].play(config)
        }
    }

    addRestart() {
        const x = 950
        const y = 935

        if (store.getters.getNotComplitedActivities.length == 0 && store.getters.getPrize) {
            const element = this.addIcon(x,y, 'restart_button').setDepth(3)
            element.on('pointerdown', async () => {
                    try {
                        await store.dispatch('restartGameAction');
                        store.commit('setCurrentActivity', 'dialogAlexGreetings');
                        this.scene.scene.start('DialogScene');
                    } catch (e) {
                        console.log(e)
                    }
                }
            )
        }
    }

    addScreeenMode() {
        const x = 1750
        const y = 135

        const element = this.addIcon(x,y, 'screen_mode')
        element.on('pointerdown', () => {
            this.playSound('keyboard_click')
            const canvas = document.querySelector('canvas');
            if (this.scene.scale.isFullscreen)
            {
                this.scene.scale.stopFullscreen();
                canvas ? canvas.classList.remove('screen_mode') : null;
            }
            else
            {
                this.scene.scale.startFullscreen();
                canvas ? canvas.classList.add('screen_mode') : null;
            }
        })
    }

    addQuestion() {
        const x = 1750
        const y = 935

        const element = this.addIcon(x,y, 'question')
        element.on('pointerdown', () => {
            showHelp(this.scene, 'openspace_help');
        })
    }

    addOpenSpace() {
        const x = 150
        const y = 135

        const element = this.addIcon(x,y, 'to_openspace')

        element.on('pointerdown', () => {
            this.playSound('keyboard_click')
            this.scene.scene.start('OpenSpaceScene')
        })
    }

    addWorkplace() {
        const x = 150
        const y = 935

        const element = this.addIcon(x,y, 'workplace')

        element.on('pointerdown', () => {
            this.playSound('keyboard_click')
            this.scene.scene.start('WorkPlaceScene')
        })
    }

    addIcon(x,y, icon) {
        this.camera = this.scene.cameras.main
        this[`${icon}_container`] = this.scene.add.container(x, y);
        this[icon] = this.scene.add.image(0, 0, icon);
        this[`${icon}_container`].setSize(this[icon].width, this[icon].height).setInteractive({cursor: 'pointer'}).setScrollFactor(0);
        this[`${icon}_container`].add(this[icon]);
        this.addIconHover(x,y, icon)
        return this[`${icon}_container`];
    }

    addIconHover(x,y, icon) {
        const iconHover = this.scene.add.image(0, 0, `${icon}_hover`).setAlpha(0);
        this[`${icon}_container`].add(iconHover);
        this[`${icon}_container`].on('pointerover', () => {
            this[icon].setAlpha(0);
            iconHover.setAlpha(1);
        });

        this[`${icon}_container`].on('pointerout', () => {
            this[icon].setAlpha(1);
            iconHover.setAlpha(0);
        });
    }
}