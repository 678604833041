<script setup>
import Interface from "@/components/BVI/HUD/Interface.vue";
import Dialog from "@/components/BVI/Dialog/Dialog.vue";
import DialogBubble from "@/components/BVI/Dialog/DialogBubble.vue";

import {onMounted, ref} from 'vue';
import authService from "../../../plugins/auth";
import {v4 as uuidv4} from "uuid";
import store from "@/store";
import Buttons from "@/components/BVI/Dialog/Buttons.vue";
import Message from "@/components/BVI/UI/Message.vue";
import router from "@/router";
import Button from "@/components/BVI/UI/Button.vue";
import metrika from "../../../plugins/metrika";

const isLoading      = ref(true);
const userId = ref(null);
const dialog = ref(null);
const screenIndex = ref(0);
const getGameStatus = async () => {
  try {
    if (process.env.NODE_ENV == 'production') {
      await authService.getProfile();
      userId.value = await store.dispatch('getUserId');
    } else {
      userId.value = uuidv4()
    }
    store.dispatch('setUserIdAction', userId.value)
    await store.dispatch('loadGameStatusAction', userId.value)
    isLoading.value = false

    if (store.getters.getNeedGreetings) {
      chooseActivity({dialog: 'dialogAlexGreetings'})
    }

    if (!store.getters.getNeedFinal && store.getters.getNotComplitedActivities.length === 0 && !store.getters.getPrize) {
      chooseActivity({dialog: 'dialogAlexFinal'})
    }
  } catch (error) {
    console.log(error)
  }
}
const buttonAction = (button) => {
  switch (button.action.name) {
    case ('setScreenAction'):
      screenIndex.value = button.action.arg;
      break;
    case ('closeDialog'):
      store.dispatch('addComplitedActivity', store.getters.getCurrentActivity)
      store.commit('setCurrentActivity', null);
      screenIndex.value = 0;
      if (store.getters.getNeedGreetings) {
        store.dispatch('setNeedGreetingsAction', false)
      }
      if (button.text === "Забрать подарок") {
        metrika('game_take_present')
        router.push("/bvi/prize/")
      }
      if (!store.getters.getNeedFinal && store.getters.getNotComplitedActivities.length === 0 && !store.getters.getPrize) {
        chooseActivity({dialog: 'dialogAlexFinal'})
      }
      break;
  }

  if (button.achievement) {
    addAchievment(button.achievement);
  }

  if (button.setStatuses) {
    updateStatuses(button.setStatuses)
  }

}

const chooseActivity = (button) => {
  store.commit('setCurrentActivity', button.dialog);
  dialog.value = store.getters.getDialog(store.getters.getCurrentActivity);
}

const addAchievment = (achievement) => {
  store.commit('setMessage', '<p class=\'h4\'>На рабочем столе<br>появился новый предмет</p>');
  store.dispatch('addAchievementAction', achievement);
  setTimeout(()=>{
    store.commit('setMessage', null);
  }, 2000)
}

const updateStatuses = (statuses) => {
  statuses.forEach(status=> {
    store.dispatch('updateStatusAction', status);
  });
}

const restartGame = async () => {
  try {
    await store.dispatch('restartGameAction');
    chooseActivity({dialog: 'dialogAlexGreetings'})
  } catch (e) {
    console.log(e)
  }
}
onMounted(async () => {
  store.state.getCurrentActivity = null;
  try {
    await getGameStatus();
  } catch (error) {
    console.log(error)
  }
});


</script>

<template>
  <main id="office" class="bvi-layout" :style="
    store.getters.getCurrentActivity && store.getters.getDialog(store.getters.getCurrentActivity).bvi_background ?
      `background-image: url(${store.getters.getDialog(store.getters.getCurrentActivity).bvi_background})` :
      `background-image: url('/assets/images/OpenSpace/open_space.png')`
  ">
    <div v-if="!isLoading" class="container bvi-no-styles">
      <Interface />
      <Message/>

      <Dialog
          v-if="store.getters.getCurrentActivity"
      >
        <template v-slot:dialog>
          <DialogBubble
              :name="dialog.title"
          >
           <p>{{store.getters.getScreen(dialog, screenIndex, true).dialogText}}</p>
          </DialogBubble>
        </template>
        <template v-slot:buttons>
          <Buttons
              :title="'Выберите ответ'"
              :buttons="store.getters.getScreen(dialog, screenIndex, true).buttons"
              @buttonClicked="(button)=>buttonAction(button)"
          />
        </template>
      </Dialog>
      <Dialog v-else-if="store.getters.getNotComplitedActivities.length > 0">
        <template v-slot:dialog>
          <DialogBubble
              :name="'Опенспейс'"
          >
            <p>Ты стоишь в офисе, окружённый кипой бумаг и мерцанием экранов компьютеров. Вокруг тебя – суета коллег, звонки телефонов и шелест клавиатур.</p>
            <br>
            <p>Но сейчас перед тобой стоит выбор: куда отправиться?</p>
          </DialogBubble>
        </template>
        <template v-slot:buttons>
          <Buttons
              v-if="store.getters.getNotComplitedActivities.length > 0"
              :title="'Куда пойти'"
              :buttons="store.getters.getNotComplitedActivities"
              @buttonClicked="(button)=>chooseActivity(button)"
          />
        </template>
      </Dialog>
      <div v-if="store.getters.getNotComplitedActivities.length == 0 && store.getters.getPrize" class="new-game bvi-no-styles message">
        <Button :button="{
          text: 'Играть еще раз'
        }"
        @click="restartGame()"
        ></Button>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
  .message {
    margin: 0px auto 50px;
  }
</style>