import {Scene, Math} from 'phaser'
import store from "@/store";
import AssetsLoader from "@/game/utils/AssetsLoader";
import UI from "@/game/utils/UI"

import background_papers from '@/game/assets/PapersArcade/background.png'
import ground from '@/game/assets/PapersArcade/ground.png'
import table1 from '@/game/assets/PapersArcade/table1.png'
import table2 from '@/game/assets/PapersArcade/table2.png'
import desk from '@/game/assets/PapersArcade/desk.png'
import monitor from '@/game/assets/PapersArcade/monitor.png'
import condey from '@/game/assets/PapersArcade/condey.png'
import cup from '@/game/assets/PapersArcade/cup.png'
import books from '@/game/assets/PapersArcade/books.png'
import flower from '@/game/assets/PapersArcade/flower.png'
import star from '@/game/assets/PapersArcade/paper.png'
import capibara from '@/game/assets/PapersArcade/capibara.png'
import bomb from '@/game/assets/PapersArcade/bomb.png'
import arrow from '@/game/assets/UI/Controller/arrow.png'
import jump from '@/game/assets/UI/Controller/jump.jpg'
import jump_sound from '@/game/assets/sound/jump.mp3'
import MobileJoystick from "../../../utils/mobileJoystick";

export default class ArcadeGameScene extends Scene {

    constructor() {
        super({key: 'ArcadeGameScene'});
        this.score = 0;
        this.scoreText;
        this.bombs;
        this.platforms;
        this.cursors;
        this.player;
        this.gameOver = false;
        this.pointer;
        this.leftButton
        this.rightButton
        this.jumpButton;
        this.pointer
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
    }

    preload() {
        this.load.image('background_papers', background_papers);
        this.load.image('ground', ground);
        this.load.image('table1', table1);
        this.load.image('table2', table2);
        this.load.image('monitor', monitor);
        this.load.image('condey', condey);
        this.load.image('desk', desk);
        this.load.image('books', books);
        this.load.image('flower', flower);
        this.load.image('cup', cup);
        this.load.image('star', star);
        this.load.image('bomb', bomb);
        this.load.spritesheet('capibara', capibara, {frameWidth: 164, frameHeight: 153});
        this.load.image('arrow', arrow);
        this.load.image('jump', jump);
        this.load.audio('jump_sound', jump_sound)
        this.mobileJoystick;
        this.cursorKeys;
        this.isMobile;
    }

    create() {

        this.sounds = {
            jump: this.sound.add('jump_sound'),
        }

        this.isMobile = this.sys.game.device.os.android || this.sys.game.device.os.iOS;

        this.add.image(0, 0, 'background_papers').setOrigin(0, 0);
        this.add.image(476,372, 'books').setOrigin(0, 0);
        this.add.image(220,614, 'books').setOrigin(0, 0);
        this.add.image(420,613, 'flower').setOrigin(0, 0);
        this.add.image(1102,675, 'cup').setOrigin(0, 0);

        const platforms = this.physics.add.staticGroup();

        platforms.create(0, 1060 - 89, 'ground').setOrigin(0, 0).refreshBody();
        platforms.create(110, 707, 'table2').setOrigin(0, 0).refreshBody();
        platforms.create(989, 707, 'table1').setOrigin(0, 0).refreshBody();

        platforms.create(268,465, 'desk').setOrigin(0, 0).refreshBody();
        platforms.create(1412,360, 'desk').setOrigin(0, 0).refreshBody();
        platforms.create(1225,584, 'monitor').setOrigin(0, 0).refreshBody();
        platforms.create(740,200, 'condey').setOrigin(0, 0).refreshBody();


        this.player = this.physics.add.sprite(this.sys.game.config.width / 2, 750, 'capibara');
        this.player.setBounce(0.2);
        this.player.setCollideWorldBounds(true);

        this.anims.create({
            key: 'left',
            frames: this.anims.generateFrameNumbers('capibara', {start: 0, end: 3}),
            frameRate: 13,
            repeat: -1
        });

        this.anims.create({
            key: 'turn',
            frames: [{key: 'capibara', frame: 4}],
            frameRate: 20
        });

        this.anims.create({
            key: 'jump',
            frames: this.anims.generateFrameNumbers('capibara', {start: 6, end: 7}),
            frameRate: 13,
            repeat: -1
        });

        this.anims.create({
            key: 'right',
            frames: this.anims.generateFrameNumbers('capibara', {start: 5, end: 8}),
            frameRate: 13,
            repeat: -1
        });

        this.physics.add.collider(this.player, platforms);

        this.cursors = this.input.keyboard.createCursorKeys();

        this.stars = this.physics.add.group({
            key: 'star',
            repeat: 12,
            setXY: {x: 110, y: 0, stepX: 140}
        });

        this.stars.children.iterate(function (child) {
            child.setBounceY(Math.FloatBetween(0.4, 0.8));
        });

        this.physics.add.collider(this.stars, platforms);

        this.physics.add.overlap(this.player, this.stars, collectStar, null, this);

        function collectStar(player, star) {
            star.disableBody(true, true);
            this.score += 10;
            this.scoreText.setText('Очки ' + this.score);

            if (this.stars.countActive(true) === 0) {
                this.stars.children.iterate(function (child) {

                    child.enableBody(true, child.x, 0, true, true);

                });

                var x = (player.x < 400) ? Math.Between(400, 800) : Math.Between(0, 400);

                let bomb
                for (var i = 0; i < 1; i++) {
                    bomb = this.bombs.create(x * (i * 5), 16, 'bomb');
                    bomb.setBounce(1);
                    bomb.setCollideWorldBounds(true);
                    bomb.setVelocity(Math.Between(-300, 300), 30);
                }

            }
        }

        this.scoreText = this.add.text(16, 16, 'Очки: 0', {font: '32px Arial', fontSize: '32px', fill: '#000'});

        this.bombs = this.physics.add.group();

        this.physics.add.collider(this.bombs, platforms);

        this.physics.add.collider(this.player, this.bombs, hitBomb, null, this);

        function hitBomb(player) {
            this.physics.pause();

            player.setTint(0xff0000);

            player.anims.play('turn');

            this.gameOver = true;

            setTimeout(() => {
                store.commit('setCurrentActivity', 'dialogPapersFinish');
                this.scene.start('DialogScene');
            }, 2000)
        }

        this.pointer = this.input.activePointer;

        this.jumpButton = this.add.circle(225, this.sys.game.config.height - 300,1000, 0x888888).setInteractive();
        this.jumpButton.setScale(.1)

        const joystickButtons = [
            {element: this.jumpButton, role: 'actionDown'},
        ]

        this.mobileJoystick = new MobileJoystick(joystickButtons)
        this.mobileJoystick.addJoystic();

        this.input.addPointer(1);
        this.joyStick = this.plugins.get('rexVirtualJoystick').add(this, {
            x: this.sys.game.config.width - 225,
            y: this.sys.game.config.height - 300,
            radius: 100,
            base: this.add.circle(0, 0, 100, 0x888888),
            thumb: this.add.circle(0, 0, 50, 0xcccccc),
            dir: '8dir',
            // forceMin: 16,
            // fixed: true,
            // enable: true
        }).on('update', this.dumpJoyStickState, this);

        this.text = this.add.text(0, 0);

        this.text = this.add.text(0, 0);
        this.dumpJoyStickState();

        if (!this.isMobile) {
            this.joyStick.setVisible(false);
            this.joyStick.setEnable(false);
            this.jumpButton.disableInteractive();
            this.jumpButton.setVisible(false);
        }

        this.sceneUI.addScreeenMode();
    }

    dumpJoyStickState() {
        this.cursorKeys = this.joyStick.createCursorKeys();
    }

    moveLeft() {
        this.player.setVelocityX(-190);
        this.player.anims.play('left', true);
    }

    jump() {
        this.sounds.jump.play({volume: 0.2})
        this.player.setVelocityY(-450);
    }

    jumping() {
        this.player.anims.play('jump', true);
    }

    moveRight() {
        this.player.setVelocityX(190);

        this.player.anims.play('right', true);
    }

    stand() {
        this.player.setVelocityX(0);

        this.player.anims.play('turn');
    }

    update ()
    {
        if (this.cursors.left.isDown || this.cursorKeys.left.isDown)
        {
            this.moveLeft()
        }

        else if (this.cursors.right.isDown || this.cursorKeys.right.isDown)
        {
            this.moveRight()
        }
        else
        {
            this.stand()
        }

        if (this.cursors.up.isDown && !this.cursorKeys.left.isDown && !this.cursorKeys.right.isDown && !this.cursors.right.isDown && !this.cursors.left.isDown) {
            this.jumping()
        }

        if (this.cursors.up.isDown && this.player.body.touching.down || this.mobileJoystick.actionDown && this.player.body.touching.down)
        {
            this.jump()
        }
    }
}
