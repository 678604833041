import axios from 'axios';
import metrika from "../../../plugins/metrika";

const apiUrl = process.env.VUE_APP_API_URL;

const state = {
    musicOn: false,
    userId: null,
    currentActivity: null,
    needGreetings: true,
    accessToken: false,
    needFinal: false,
    prize: null,
    complitedActivities: [],
    statuses: {
        comrades: {
            name: 'Отношения с коллективом',
            count: 0
        },
        director: {
            name: 'Отношения с руководителем',
            count: 0
        },
        psychological: {
            name: 'Психологическое состояние',
            count: 0
        },
        effectiveness: {
            name: 'Личная эффективность',
            count: 0
        },
    },
    achievements: [],
    gameActivites: [
        {text: "Планёрка", dialog: "dialogPlanningUp", scene: 'DialogScene', x: 410, y: 400,},
        {text: "Принтер", dialog: "dialogPrinter", scene: 'DialogScene', x: 860, y: 545},
        {text: "Кондиционер", dialog: "dialogConditioner", scene: 'DialogScene', x: 1420, y: 200},
        {text: "Гора бумаг", dialog: "dialogPapers", scene: 'DialogScene', x: 1445, y: 580},
        {text: "Коллеги", dialog: "dialogComrades", scene: 'DialogScene', x: 1070, y: 480,},
    ],
};

const mutations = {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    switchMusic(state) {
        state.musicOn = !state.musicOn;
    },
    setUserId(state, userId) {
        if (!localStorage.getItem('userId') || localStorage.getItem('userId') !== userId) {
            localStorage.setItem('userId', userId);
        }
        state.userId = userId;
    },
    loadGameStatus(state, data) {
        const statusesKeys = [];
        //Собираем ключи статусов, чтобы корректо обновить
        for (let key in state.statuses) {
            statusesKeys.push(key)
        }

        for (let key in data) {
            if (statusesKeys.includes(key)) {
                state.statuses[key].count = data[key]
            } else {
                state[key] = data[key];
            }

        }
    },
    resetComplitedActivities(state) {
        state.complitedActivities = []
    },
    setCurrentActivity(state, dialog) {
        state.currentActivity = dialog;
    },
    setNeedGreetings(state, status) {
        state.needGreetings = status;
    },
    addComplitedActivity(state, activity) {
        let complitedActivities = !state.complitedActivities ? [] : state.complitedActivities
        if (!complitedActivities.includes(activity)) {
            complitedActivities.push(activity);
            state.complitedActivities = complitedActivities;
        }
    },
    addAchievement(state, achievement) {
        if (!state.achievements.includes(achievement)) {
            state.achievements.push(achievement);
        }
    },
    updateStatus(state, args) {
        state.statuses[args.status].count += args.count;
    },
    setPrize(state, prize) {
        state.needFinal = false;
        state.prize = prize;
    },
    setNeedFinal(state, bool) {
        state.needFinal = bool;
    },
};

const actions = {
    setUserIdAction({commit}, userId) {
        commit('setUserId', userId);
    },
    setNeedFinalAction({ commit }, bool) {
        commit("setNeedFinal", bool)
    },
    async getUserId({state, commit }) {
        try {
            const response = await axios.post(
                `${process.env.VUE_APP_AUTH_URL}connect/userinfo`,
                {},  // Пустое тело запроса
                {
                            headers: {
                                'Authorization': `Bearer ${state.accessToken}`
                            }
                        }
            );
            console.log(response.data)
            commit('setUserId', response.data.user_id);
            return response.data.user_id;
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async setNeedGreetingsAction({ state, commit }, status) {
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                {
                        token: state.accessToken,
                        user_id: state.userId,
                        needGreetings: status
                    }
            );
            commit('setNeedGreetings', status);
            return response;
        } catch(error) {
            console.error(error)
            throw error;
        }

    },
    async addComplitedActivity({state,  commit }, activity) {
        commit('addComplitedActivity', activity);
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                {
                        token: state.accessToken,
                        user_id: state.userId,
                        complitedActivities: JSON.stringify(state.complitedActivities)
                    }
            );
            return response
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async addAchievementAction({state, commit}, achievement) {
        commit('addAchievement', achievement)
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                {
                        token: state.accessToken,
                        user_id: state.userId,
                        achievements: JSON.stringify(state.achievements)
                    }
            );
            return response
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async updateStatusAction({state, commit}, args) {
        commit('updateStatus', args)
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                JSON.parse(`{ 
                    "token": "${state.accessToken}",
                    "user_id": "${state.userId}",
                    "${args.status}": ${state.statuses[args.status].count}
                }`)
            );
            return response
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async loadGameStatusAction({state,  commit }, userId) {
        try {
            const response = await axios.post(`${apiUrl}/game_status`, {
                token: state.accessToken,
                user_id: userId
            });
            const updatedState = response.data;
            commit('loadGameStatus', updatedState.data)
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async loadGameSharedStatusAction({ commit }, userId) {
        try {
            const response = await axios.post(`${apiUrl}/shared`, {
                user_id: userId
            });
            const updatedState = response.data;
            commit('loadGameStatus', updatedState.data)
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async setPrizeAction({state, commit }) {
        try {
            const response = await axios.put(`${apiUrl}/get_prize`, {
                token: state.accessToken,
                user_id: state.userId
            });
            commit('setPrize', response.data);
            // console.log(response.data)
            return response.data;
        } catch(error) {
            console.error(error.message)
            throw error
        }
    },
    async restartGameAction({state, commit }) {
        const response = await axios.post(`${apiUrl}/game_restart`, {
            token: state.accessToken,
            user_id: state.userId
        });
        metrika('game_play_more')
        const updatedState = response.data;
        commit('loadGameStatus', updatedState.data)
    }
};

const getters = {
    isBVImode: () => {
        var cookies = document.cookie;

        // Ищем куки bvi_target
        var bviCookie = cookies.split('; ').find(row => row.startsWith('bvi_target='));

        // Если куки bvi_target найдены и они не пусты
        if (bviCookie && bviCookie.split('=')[1]) {
            return true;
        }

        return false;
    },
    getMusicStatus: state => {
        return state.musicOn;
    },
    getUserId: state => {
        return state.userId;
    },
    getCurrentActivity: state => {
        return state.currentActivity;
    },
    getNeedGreetings: state => {
        return state.needGreetings;
    },
    getNeedFinal: state => {
        return state.needFinal;
    },
    getComplitedActivities: state => {
        return !state.complitedActivities ? [] : state.complitedActivities;
    },
    getGameActivites: state => {
        return state.gameActivites;
    },
    getNotComplitedActivities: state => {
        return !state.complitedActivities ?
            state.gameActivites :
            state.gameActivites.filter(
                activity=>!state.complitedActivities.includes(activity.dialog)
            );
    },
    getStatuses: state => {
        return state.statuses;
    },
    getStatus: state => status => {
        return state.statuses[status].count;
    },
    getAchievements: state => {
        return state.achievements;
    },
    getPrize: state => {
        return state.prize;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
